export const navigation = {
  categories: [
    {
      id: 'fireworks',
      name: 'Fireworks',
      featured: [
        {
          name: 'New Arrivals',
          href: '/fireworks/rockets/small_rockets',
          imageSrc: '/products/blauer_berber.jpg',
          imageAlt: 'New fireworks arrivals',
        },
        {
          name: 'Best Sellers',
          href: '/fireworks/rockets/small_rockets',
          imageSrc: '/products/double_force.jpg',
          imageAlt: 'Best selling fireworks',
        },
      ],
      sections: [
        {
          id: 'rockets',
          name: 'Rockets',
          items: [
            { name: 'Small Rockets', id: 'small_rockets', href: '/fireworks/rockets/small' },
            { name: 'Medium Rockets', id: 'medium_rockets', href: '/fireworks/rockets/medium' },
            { name: 'Large Rockets', id: 'large_rockets', href: '/fireworks/rockets/large' },
          ],
        },
        {
          id: 'crackers',
          name: 'Crackers',
          items: [
            { name: 'Small Crackers', id: 'small_crackers', href: '/fireworks/crackers/small' },
            { name: 'Medium Crackers', id: 'medium_crackers', href: '/fireworks/crackers/medium' },
            { name: 'Large Crackers', id: 'large_crackers', href: '/fireworks/crackers/large' },
          ],
        },
        {
          id: 'sparklers',
          name: 'Sparklers',
          items: [
            { name: 'Handheld Sparklers', id: 'handheld_sparklers', href: '/fireworks/sparklers/handheld' },
            { name: 'Ground Sparklers', id: 'ground_sparklers', href: '/fireworks/sparklers/ground' },
          ],
        },
      ],
    },
    {
      id: 'accessories',
      name: 'Accessories',
      featured: [
        {
          name: 'accessories',
          href: '/fireworks/rockets/small_rockets',
          imageSrc: '/products/lightning_torch.jpg',
          imageAlt: 'accessories',
        },
        {
          name: 'Launching Stands',
          href: '/fireworks/rockets/small_rockets',
          imageSrc: '/products/stand.jpg',
          imageAlt: 'Fireworks launching stands',
        },
      ],
      sections: [
        {
          id: 'accessories',
          name: 'Safety Gear',
          items: [
            { name: 'accessories', id: 'accessories', href: '/accessories/accessories/accessories' },
            { name: 'Goggles', id: 'goggles', href: '/accessories/safety-gear/goggles' },
            { name: 'torch', id: 'torch', href: '/accessories/accessories/' },
          ],
        },
        {
          id: 'launching_stands',
          name: 'Launching Stands',
          items: [
            { name: 'Single Tube Stands', id: 'single_tube_stands', href: '/accessories/launching-stands/single-tube' },
            { name: 'Multi Tube Stands', id: 'multi_tube_stands', href: '/accessories/launching-stands/multi-tube' },
          ],
        },
      ],
    },
  ],
  pages: [
    { name: 'Company', id: '/company' },
    { name: 'Stores', id: '/stores' },
  ],
};
