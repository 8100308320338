export const SellData = [
    {
        "imageUrl":"/products/berserk.jpg",
        "title": "BERSERK",
        "price": "80 €"
    },
    {
        "imageUrl":"/products/Booze_brothers.jpg",
        "title": "BOOZE BROTHER",
        "price": "80 €"

    },
    {
        "imageUrl":"/products/brutal_buffalo.jpg",
        "title": "BRUTAL BUFFALO",
        "price": "80 €"

    },
    {
        "imageUrl":"/products/double_force.jpg",
        "title": "DOUBLE FORCE",
        "price": "80 €"

    },
    {
        "imageUrl":"/products/lightning_torch.jpg",
        "title": "LIGHTNING TORCH",
        "price": "90 €"
    },
    {
        "imageUrl":"/products/pyroshow_2000_hermes.jpg",
        "title": "PYROSHOW 2000 HERMES",
        "price": "90 €"
    }
    ,
    {
        "imageUrl":"/products/toschpyros_X_verbund.jpg",
        "title": "TOSCHPYROS X VERBUND",
        "price": "80 €"
    }
  
]