export const homeCarouselData=[
    {
        image:"/fireworks/firework001.jpg",
        path:"/fireworks/"
    },
    {
        image:"/fireworks/firework002.jpg",
        path:"/fireworks/"
    },
    {
        image:"/fireworks/firework003.jpg",
        path:"/fireworks/"
    },
    {
        image:"/fireworks/firework004.jpg",
        path:"/fireworks/"
    },
    {
        image:"/fireworks/firework005.jpg",
        path:"/fireworks/"
    }
]



